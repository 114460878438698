<template>
  <div>
    <BusinessHeader v-if="user.role === 'superadmin'"> </BusinessHeader>

    <span
      class="mt-4 inline text-2xl font-semibold text-gray-600"
      v-if="user.role !== 'superadmin'"
    >
      Mass Text History
    </span>

    <template v-if="mass_texts.length == 0">
      <div
        class="border-gray-300 border-2 text-center py-3 text-red-500 text-base"
      >
        No mass texts as of the moment
      </div>
    </template>
    <template v-else>
      <div class="flex-row inline-flex w-full py-4 mt-4">
        <div class="w-1/4 text-center">Date & Time</div>
        <div class="w-1/4 text-center">Total Recipients</div>
        <div class="w-auto text-center pl-6">Title</div>
        <!-- <div class="w-1/4"></div> -->
      </div>

      <div
        class="flex-row inline-flex w-full border-t-2 border-gray-200 bg-white py-3"
        v-for="mass_text in mass_texts"
        :key="mass_text.id"
      >
        <router-link
          class="flex-row inline-flex w-full"
          :to="
            user.role === 'superadmin'
              ? `/businesses/${this.$route.params.business_id}/mass-text-history/${mass_text.id}`
              : `/mass-text/history/${mass_text.id}`
          "
        >
          <div class="w-1/4 text-center">
            <a href="#" class="text-blue-400 ml-1">
              {{ transformDate(mass_text.mass_text_timestamp.seconds) }}
            </a>
          </div>
          <div class="w-1/4 text-center">
            {{ mass_text.mass_text_recipients_count }}
          </div>

          <div class="w-auto text-center capitalize pl-6">
            {{ mass_text.mass_text_title }}
          </div>
        </router-link>
      </div>
    </template>

    <div
      class="flex-row inline-flex w-full border-t-2 border-gray-200 bg-white py-3"
      v-if="mass_texts.length != 0"
    >
      <div class="w-1/4 text-center"></div>
      <div class="w-1/4 text-center"></div>
      <div class="w-1/4 text-right">
        {{ mass_texts.length }} Mass Text Histories
      </div>
    </div>

    <div
      class="h-screen w-full fixed top-0 right-0 bg-gray-700 opacity-25"
      v-if="detailsModal"
    ></div>

    <!-- view mass text history details modal -->
    <div
      class="h-screen w-full flex items-center justify-center bg-teal-lightest fixed top-0 overflow-auto right-0 lg:business-modal-lg "
      v-if="detailsModal"
    >
      <div
        class="h-screen w-full absolute flex items-center justify-center bg-modal"
      >
        <div class="bg-white rounded shadow pb-8 m-4 max-h-full text-center">
          <div class="mb-4 bg-gray-700 p-4 text-white text-base">
            Mass Text History Details
          </div>
          <div class="flex flex-col mt-7 border-2 border-gray-400">
            <div class="flex justify-between w-full px-3">
              <div>Date:</div>
              <div>
                {{ transformDate(mass_text.mass_text_timestamp.seconds) }}
              </div>
            </div>
            <div class="flex justify-between w-full px-3">
              <div class="mr-5">Total Recipients:</div>
              <div>
                {{ mass_text.mass_text_recipients_count }}
              </div>
            </div>
            <div class="flex justify-between w-full px-3">
              <div>Title:</div>
              <div>
                {{ mass_text.mass_text_title }}
              </div>
            </div>
            <div class="flex justify-between w-full px-3">
              <div>Message:</div>
              <div>
                {{ mass_text.mass_text_message }}
              </div>
            </div>
            <div class="flex justify-between w-full px-3">
              <div>Image:</div>
              <div>
                {{ mass_text.mass_text_message }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BusinessHeader from "./BusinessHeader";
const firebase = require("../firebaseConfig");
const { DateTime } = require("luxon");

export default {
  name: "MassTextHistoryComponent",
  components: {
    BusinessHeader,
  },
  data: () => ({
    locations: [],
    detailsModal: false,
    mass_text: {},
    mass_texts: [],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    user: {},
  }),
  async mounted() {
    this.user = await this.getUserInfo();
    this.checkPageAccess();
    this.getBusinessInfo();
    this.getMassTexts();
    this.checkPageAccess();
  },
  methods: {
    getBusinessInfo() {
      return new Promise((resolve, reject) => {
        try {
          firebase.db
            .collection("users")
            .doc(firebase.auth.currentUser.uid)
            .onSnapshot((doc) => {
              resolve({
                id: doc.id,
                ...doc.data(),
              });
            });
        } catch (e) {
          reject(e);
        }
      });
    },
    getUserInfo() {
      return new Promise((resolve, reject) => {
        firebase.db
          .collection("users")
          .doc(firebase.auth.currentUser.uid)
          .get()
          .then((user) => {
            resolve({ id: user.id, ...user.data() });
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async checkPageAccess() {
      const business = await firebase.db
        .collection("businesses")
        .doc(this.user.business_id)
        .get();
      if (!business.data().mass_text_active) {
        this.$router.push("/dashboard");
      }
    },
    async getMassTexts() {
      const business = await this.getBusinessInfo();
      firebase.db
        .collection("businesses")
        .doc(
          business.business_id
            ? business.business_id
            : this.$route.params.business_id
        )
        .collection("mass_texts")
        .orderBy("mass_text_timestamp", "desc")
        .onSnapshot({ includeMetadataChanges: true }, (docs) => {
          this.mass_texts = [];
          docs.forEach((doc) => {
            this.mass_texts.push({
              id: doc.id,
              ...doc.data(),
            });
          });
        });
    },
    transformDate(seconds) {
      let date = new Date(0);
      date.setUTCSeconds(seconds);
      const ISOdate = date.toISOString();

      return DateTime.fromISO(ISOdate)
        .setZone("America/Detroit")
        .toFormat("LLLL dd, yyyy hh:mm a");
    },
  },
};
</script>
