<template>
  <MassTextHistoryComponent />
</template>

<script>
import MassTextHistoryComponent from '../components/MassTextHistoryComponent.vue'

export default {
  name: 'MassTextHistory',
  components: {
    MassTextHistoryComponent,
  },
}
</script>
