import { render } from "./MassTextHistory.vue?vue&type=template&id=a4d2db6e"
import script from "./MassTextHistory.vue?vue&type=script&lang=js"
export * from "./MassTextHistory.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "a4d2db6e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('a4d2db6e', script)) {
    api.reload('a4d2db6e', script)
  }
  
  module.hot.accept("./MassTextHistory.vue?vue&type=template&id=a4d2db6e", () => {
    api.rerender('a4d2db6e', render)
  })

}

script.__file = "src/views/MassTextHistory.vue"

export default script